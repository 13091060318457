import React, {useState} from 'react';
import './App.css';

function App() {
    let [errorMessage, setErrorMessage] = useState<string>("");
    let [successMessage, setSuccessMessage] = useState<string>("");
    let [infoMessage, setInfoMessage] = useState<string>("");
    let [pending, setPending] = useState<boolean>(false);

    const sendSms = () => {
        const url = new URL(`${process.env.REACT_APP_BACKEND_URL}/send-affection-sms`);
        url.searchParams.set('message', 'toggle light!');
        let headers;
        console.log(process.env.NODE_ENV);
        if (process.env.NODE_ENV === 'production') {
            headers = {
                "Access-Control-Allow-Origin": "*"
            }
        }
        return fetch(url, { headers });
    };

    const changeLamp = () => {
        console.log('isPending?', pending);
        if (!pending) {
            setPending(true);
            setInfoMessage('Anfrage wird gesendet');
            sendSms().then((res) => {
                setInfoMessage('');
                if (res.ok) {
                    setSuccessMessage('Licht wird ein bzw. ausgeschaltet.');
                    setTimeout(() => {
                        setSuccessMessage('');
                    }, 8000);
                }
                setErrorMessage('Etwas ist schiefgelaufen :(');
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            }).catch(() => {
                setInfoMessage('');
                setErrorMessage('Etwas ist schiefgelaufen :(');
                setTimeout(() => {
                    setErrorMessage('');
                }, 5000);
            }).finally(() => {
                setPending(false);
            });
        }
    };

    return (
        <>
            <h4>Du willst das Licht an- bzw. ausschalten?</h4>
            <h3>Klick die Glühbirne!</h3>
            <img alt='lamp' src='img/lamp-off.png' onClick={changeLamp}/>
            {infoMessage &&
                <div className='snackbar info-message'>
                    {infoMessage}
                </div>
            }
            {errorMessage &&
                <div className='snackbar error-message'>
                    {errorMessage}
                </div>
            }
            {successMessage &&
                <div className='snackbar success-message'>
                    {successMessage}
                </div>
            }
        </>
    );
}

export default App;
